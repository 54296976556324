@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@layer components {
  .H6 {
    @apply text-black font-font font-bold text-[14px] text-center;
  }
  .H5 {
    @apply text-black font-font font-bold text-sm lg:text-xl mb-8 text-center;
  }
  .H4 {
    @apply font-font font-bold text-xl;
  }
  .viewHeader {
    @apply text-black font-font font-bold text-2xl m-8 mt-20 text-center uppercase;
  }
  .H3 {
    @apply text-black font-font font-bold text-[1.25rem] lg:text-[2rem] mb-8 text-center leading-8 uppercase
  }
  .max-container {
    max-width: 1440px;
    margin: 0 auto;
  }
  .error-style {
    @apply flex flex-col justify-center items-center -mt-8 font-bold text-sm text-orange;
  }
  .button-style {
    @apply font-normal text-base font-secondaryFont cursor-pointer rounded-3xl border border-lite-pink bg-TransparantOrange box-border py-2 px-10 disabled:opacity-40 shadow-sm;
    @apply hover:transition hover:duration-300 ease-in-out hover:bg-transparant-orange hover:border hover:border-orange
  }
  .paper {
    @apply text-center bg-white box-border p-5;
    @apply m-auto w-[90%] lg:w-[70rem] 2xl:w-[100rem];
  }
  .eval-paper {
    @apply paper mt-8 p-0 flex flex-wrap max-lg:flex-col flex-row;
  }
  .eval-border {
    @apply border-[0.1rem] border-light-gray;
  }
  .half-screen-wrapper {
    @apply w-1/2 max-lg:w-full;
  }
  .mt-models-rows > * {
    @apply table-cell align-middle py-1;
    @apply border-dashed border-t-[0.5px] border-red-300;
  }
  .mt-models-lower-row > * {
    @apply border-b-[0.5px] border-red-300;
  }
  .real-datasets-rows > * {
    @apply table-cell align-middle py-2;
    @apply border-t-2 border-light-gray;
  }
  .real-datasets-headers > * {
    @apply table-cell align-middle py-3.5;
  }
  .synthetic-datasets-rows > * {
    @apply table-cell align-middle py-0.5;
  }
  .eval-table-style{
    @apply min-w-[8rem] max-w-[12.5rem] min-h-[2rem] max-h-[3rem] py-[0.25rem] border-[0.063rem] border-gray;
  }  
}
@layer utilities {
  .background-logo {
    @apply bg-syndata-logo bg-center bg-no-repeat bg-contain;
  }
  .gradient-text {
    @apply bg-gradient-to-b from-semi-orange to-red-600 bg-clip-text text-transparent;
  }
  .gradient-background {
    @apply bg-gradient-to-bl from-semi-orange to-orange;
  }
  .flex-view {
    @apply flex items-center justify-center;
  }
}
